import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import Navbar from '../../hooks/Navbar';
import '../../scss/pages/administration/EditProfile.scss'
import { Navigate, useNavigate } from 'react-router-dom';
import useAuth from '../../useAuth';
import { PacmanLoader } from 'react-spinners';
import { Helmet } from 'react-helmet';

const EditProfile = () =>{
    const navigate = useNavigate()
    const {user, loading} = useAuth();
    const [navbarVisible, setNavbarVisible] = useState(false);
    const [navbarClosing, setNavbarClosing] = useState(false);

    const openNavbar = () => {
        setNavbarClosing(false);
        setNavbarVisible(true);
    };

    const closeNavbar = () => {
        setNavbarClosing(true);
        setTimeout(() => {
            setNavbarVisible(false);
        }, 500);
    };

    const changeUsername = (e) =>{
        navigate(`/ProfileManagement/username/${user.id}`)
    }

    const changeEmail = (e) =>{
        navigate(`/ProfileManagement/email/${user.id}`)
    }

    const changePassword = (e) =>{
        navigate(`/ProfileManagement/password/${user.id}`)
    }

    const logout = () => {
        localStorage.clear()
        navigate('/');
    };

    const deleteAccount= () =>{
        navigate('/deleteAccount');
    }

    if (loading) {
        return <div style={{minHeight:'100vh', width:'100%', display: 'flex', justifyContent:'center', alignItems: 'center'}}><PacmanLoader color='#2478DC' /></div>
    }

    if(!user){
        return <Navigate to="/authentication/login" />;
    }else{
        return (
            <div className='EditManagement'>
                <Helmet>
                    <title>تعديل الملف الشخصي</title>
                    <meta name="description" content="قم بتحديث معلوماتك الشخصية على منصة الميم و التعليم." />
                    <meta property="og:title" content="تعديل الملف الشخصي - الميم و التعليم" />
                    <meta property="og:description" content="قم بتحديث معلوماتك الشخصية على منصة الميم و التعليم." />
                    <meta property="og:image" content="https://edu.memes.tn/preview.png" />
                    <meta property="og:url" content="https://edu.memes.tn/EditProfile" />
                    <meta property="og:type" content="website" />
                    <meta property="og:locale" content="ar_TN" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="تعديل الملف الشخصي - الميم و التعليم" />
                    <meta name="twitter:description" content="قم بتحديث معلوماتك الشخصية على منصة الميم و التعليم." />
                    <meta name="twitter:image" content="https://edu.memes.tn/preview.png" />
                    <link rel="canonical" href="https://edu.memes.tn/EditProfile" />
                </Helmet>
                <div className="navigation">
                    <button id="navigate" onClick={openNavbar}><FontAwesomeIcon icon={faBars} size="xl" /></button>
                </div>
                {navbarVisible && <Navbar toggleNavbar={closeNavbar} closing={navbarClosing} />}
    
                <h1> تعديل الحساب </h1>
                <div className='options'>
                    <button className='card' onClick={(e) => changeUsername(e)}>
                        إسم المستخدم    
                    </button>
    
                    <button className='card' onClick={(e) => changeEmail(e)}>
                        البريد الإلكتروني
                    </button>
    
                    <button className='card' onClick={(e) => changePassword(e)}>
                        كلمة المرور
                    </button>
    
                    <button className='card' onClick={(e) => logout(e)}>
                        تسجيل الخروج
                    </button>
                </div>
                <button id='delete-account' onClick={deleteAccount}>
                    حذف الحساب      
                </button>
            </div>
            
        )
    }
}

export default EditProfile;