import React, { useEffect, useState, useRef } from "react";
import '../scss/pages/Subjects.scss';
import axios from "axios";
import { SERVER } from "../config/config";
import { Navigate, useNavigate } from 'react-router-dom';
import useAuth from '../useAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faPlus, faFilter } from '@fortawesome/free-solid-svg-icons';
import Card from "../hooks/Card";
import NotFound from "../hooks/NotFound";
import Navbar from '../hooks/Navbar';
import { PacmanLoader } from 'react-spinners';
import Info from "../hooks/Info";
import { Helmet } from 'react-helmet';
import uncanny from '../utils/soundeffects/uncanny1.mp3'

const Subjects = () => {
    localStorage.removeItem('Subject')
    const navigate = useNavigate();
    const {user, loading} = useAuth();
    const [subjects, setSubjects] = useState([]);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [navbarVisible, setNavbarVisible] = useState(false);
    const [navbarClosing, setNavbarClosing] = useState(false);
    const dropdownRef = useRef(null);
    const [first_login, setFirstLogin] = useState(Boolean(localStorage.getItem('first_login')))
    const [animateNotReady, setAnimateNotReady] = useState(false);
    const audioRef = React.useRef(new Audio(uncanny));

    const handleSubjectNotReadyClick = () => {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        setAnimateNotReady(true);
        audioRef.current = new Audio(uncanny);
        audioRef.current.play().catch((err) => console.error("Audio playback failed:", err));
        setTimeout(() => setAnimateNotReady(false), 1000);
    };

    useEffect(() => {
        const fetchSubjects = async () => {
            try {
                const response = await axios.get(`${SERVER}/subjects/subjects`);
                setSubjects(response.data);
            } catch (error) {
                console.log(error);
            }
        };

        fetchSubjects();

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownVisible(false);
        }
    };

    const addSubject = () => {
        navigate('/subjectManagement');
    };

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const openNavbar = () => {
        setNavbarClosing(false);
        setNavbarVisible(true);
    };

    const closeNavbar = () => {
        setNavbarClosing(true);
        setTimeout(() => {
            setNavbarVisible(false);
        }, 500);
    };

    const setAccept = () =>{
        localStorage.removeItem('first_login')
        setFirstLogin(false)
    }

    if (loading) {
        return <div style={{minHeight:'100vh', width:'100%', display: 'flex', justifyContent:'center', alignItems: 'center'}}><PacmanLoader color='#2478DC' /></div>
    }
    if(!user){
        return <Navigate to="/authentication/login" />;
    }else{
        return (
            <div className="subjects">
                <Helmet>
                    <title>المواد</title>
                    <meta name="description" content="اكتشف مجموعة المواد الدراسية وتعلم معنا في الميم و التعليم." />
                    <meta property="og:title" content="المواد الدراسية - الميم و التعليم" />
                    <meta property="og:description" content="اكتشف مجموعة المواد الدراسية وتعلم معنا في الميم و التعليم." />
                    <meta property="og:image" content="https://edu.memes.tn/preview.png" />
                    <meta property="og:url" content="https://edu.memes.tn/subjects" />
                    <meta property="og:type" content="website" />
                    <meta property="og:locale" content="ar_TN" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="المواد الدراسية - الميم و التعليم" />
                    <meta name="twitter:description" content="اكتشف مجموعة المواد الدراسية وتعلم معنا في الميم و التعليم." />
                    <meta name="twitter:image" content="https://edu.memes.tn/preview.png" />
                    <link rel="canonical" href="https://edu.memes.tn/subjects" />
                </Helmet>
                {first_login && (
                <>
                    <div className="modal-overlay" />
                    <Info setAccept={setAccept} />
                </>)}
                <div className="navigation">
                    { user && user.role !== 'user' && <button id="add"><FontAwesomeIcon icon={faPlus} onClick={addSubject} size="xl" /></button>}
                    <button id="navigate" onClick={openNavbar}><FontAwesomeIcon icon={faBars} size="xl" /></button>
                </div>
                {navbarVisible && <Navbar toggleNavbar={closeNavbar} closing={navbarClosing} />}
                <div className="main">
                    <h1>المواد</h1>
                    {/* <div className="levels" onClick={toggleDropdown}>
                        <span>المستوى: {localStorage.getItem('level') || 'اختر المستوى'}</span>
                        <button onClick={toggleDropdown}><FontAwesomeIcon icon={faFilter} /></button>
                        {dropdownVisible && (
                            <div ref={dropdownRef} className="dropdown">
                            </div>
                        )}
                    </div>*/}
                    <span> المستوى : السّنة التّاسعة من التّعليم الأساسي </span>
                    <div className="subjects-section">
                        {/* Split subjects into available and not available */}
                        {subjects.length > 0 ? (
                            <>
                                {/* Available subjects (subject.ready === true) */}
                                {subjects.filter(subject => subject.ready).length > 0 && (
                                    <div className="available-subjects">
                                        <h2>المواد المتوفرة</h2>
                                        <div className="subject-elements">
                                            {subjects
                                                .filter(subject => subject.ready)
                                                .map((subject, index) => (
                                                    <Card
                                                        key={`available-${index}`}
                                                        className='subject-card'
                                                        subject={subject}
                                                        role={user && user.role}
                                                    />
                                            ))}
                                        </div>
                                    </div>
                                )}

                                {subjects.filter(subject => !subject.ready).length > 0 && (
                                    <div className="not-available-subjects">
                                        <h2 className={animateNotReady ? 'animate-subject' : ''}>المواد القادمة قريباً</h2>
                                        <div className="subject-elements">
                                            {subjects
                                                .filter(subject => !subject.ready)
                                                .map((subject, index) => (
                                                    <Card
                                                        key={`available-${index}`}
                                                        className='subject-card'
                                                        subject={subject}
                                                        role={user && user.role}
                                                        onSubjectNotReadyClick = {handleSubjectNotReadyClick}
                                                    />
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </>
                        ) : (
                            <NotFound role={user && user.role} action={addSubject} />
                        )}
                    </div>
                </div>
            </div>
        );
    }
};

export default Subjects;
