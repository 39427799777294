// NotAllowed.js
import React from 'react';
import '../scss/utils/NotAllowed.scss';
import superUncanny from '../utils/mrincredible/superuncanny.png'
import HomeNavbar from './HomeNavbar';

const NotValid = () => {
  return (
    <div className='not-allowed'>
        <HomeNavbar />
        <div className='content'>
            <img src={superUncanny} alt='uncanny' />
            <span>عذرًا، لكن رابط إعادة تعيين كلمة المرور غير صالح. يرجى ملاحظة أن رابط إعادة تعيين كلمة المرور ينتهي بعد 15 دقيقة. يمكنك المحاولة مرة أخرى.</span>
        </div>
    </div>
  );
};

export default NotValid;
