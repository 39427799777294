import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faTiktok, faYoutube, faGooglePlay, faApple } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import einstein from '../utils/main_page/einstein.png'
import '../scss/utils/Footer.scss'
import appStore from '../utils/appstore.svg'
import playStore from '../utils/playstore.svg'

const Footer = () =>{
    const visitSite = (link) =>{
        window.open(link, '_blank');
    }

    return(
        <div className="Footer">
            <div className="footer-contact-us">
                <span> تواصل معنا </span>
                <div className="icons">
                    <a href="https://www.instagram.com/edu.memes.tn/" target='_blank'><FontAwesomeIcon icon={faInstagram} style={{color: "#F4F5F9",}} /></a>
                    <a href="https://www.facebook.com/edu.memes.tn/" target='_blank'><FontAwesomeIcon icon={faFacebookF} style={{color: "#F4F5F9",}} /></a>
                    <a href="https://www.tiktok.com/@edu.memes.tn" target='_blank'><FontAwesomeIcon icon={faTiktok} style={{color: "#F4F5F9",}} /></a>
                    <a href="https://www.youtube.com/@edu-memes-tn" target='_blank'><FontAwesomeIcon icon={faYoutube} style={{color: "#F4F5F9",}} /></a>
                    <a href="https://mail.google.com/mail/?view=cm&fs=1&to=edu.memes.tn@gmail.com" target='_blank'><FontAwesomeIcon icon={faEnvelope} style={{color: "#F4F5F9",}} /></a>
                </div>
            </div>
            <div className="footer-main">
                <img src={einstein} alt='einstein' />
                <div className='footer-content'>
                    <span> المزيد </span>
                    <span id='aboutus' onClick={() => window.open('/AboutUs', '_self')}> تعرّف أكثر </span>
                    <p> جميع الحقوق محفوظة© </p>
                </div>
                <img src={einstein} alt='einstein' />
            </div>
            <div className="footer-apps">
                <span> لا تنسى تحميل التّطبيق </span>
                <div className='footer-buttons'>
                    <button onClick={() => visitSite('https://apps.apple.com/tn/app/edu-memes-%D8%A7%D9%84%D9%85%D9%8A%D9%85-%D9%88-%D8%A7%D9%84%D8%AA%D8%B9%D9%84%D9%8A%D9%85/id6736692795')}> 
                        <img src={appStore} />
                    </button>
                    <button onClick={() => visitSite('https://play.google.com/store/apps/details?id=com.guitouni.edumemes')}> 
                        <img src={playStore} />
                    </button>
                </div>
                <p> جميع الحقوق محفوظة© </p>
            </div>
        </div>
    )
}

export default Footer;