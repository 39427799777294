import React, { useEffect, useState } from "react";
import '../scss/pages/ChapterManagement.scss';
import axios from "axios";
import { SERVER } from "../config/config";
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import useAuth from '../useAuth';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Navbar from "../hooks/Navbar";
import { PacmanLoader } from 'react-spinners';
import { Helmet } from 'react-helmet';

const ChapterManagement = () => {
    const { subjectID, chapterID } = useParams();
    const navigate = useNavigate();
    const {user, loading} = useAuth();
    const [chapter, setChapter] = useState();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [imagePreview, setImagePreview] = useState('');
    const [imagePerc, setImagePerc] = useState(0);
    const [inputs, setInputs] = useState({});
    const [navbarVisible, setNavbarVisible] = useState(false);
    const [navbarClosing, setNavbarClosing] = useState(false);

    useEffect(() => {
        const fetchChapter = async () => {
            try {
                const response = await axios.get(`${SERVER}/chapters/getChapter`, {
                    params: {
                        chapterID : chapterID
                    }
                });
                setChapter(response.data)
                setTitle(response.data.title)
                setDescription(response.data.description)
                setImagePreview(SERVER+response.data.img)
            } catch (error) {
                console.log(error);
            }
        };
        if (chapterID) {
            fetchChapter();
        }
    }, [chapterID]);

    const handleImageClick = () => {
        document.getElementById('fileInput').click();
    };

    useEffect(() => {
        if (selectedImage) {
            setImagePreview(URL.createObjectURL(selectedImage));
        }
    }, [selectedImage]);

    const openNavbar = () => {
        setNavbarClosing(false);
        setNavbarVisible(true);
    };

    const closeNavbar = () => {
        setNavbarClosing(true);
        setTimeout(() => {
            setNavbarVisible(false);
        }, 500);
    };

    const saveChapter = async (e) => {
        e.preventDefault();

        try {
            const formData = new FormData();
            formData.append('title', title); 
            formData.append('description', description);
            formData.append('subjectID', subjectID)
            if (selectedImage) {
                formData.append('image', selectedImage);
            }

            if (chapterID) {
                formData.append('chapterID', chapterID)
                await axios.put(`${SERVER}/chapters/editChapter`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            } else {
                await axios.post(`${SERVER}/chapters/addChapter`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            }

            resetFields();
            navigate(`/chapters/${subjectID}`);
        } catch (error) {
            console.log(error);
        }
    };

    const resetFields = () => {
        setTitle('');
        setDescription('');
        setSelectedImage(null);
        setImagePreview('');
        setImagePerc(0);
    };

    const maxChars = 200;

    if (loading) {
        return <div style={{minHeight:'100vh', width:'100%', display: 'flex', justifyContent:'center', alignItems: 'center'}}><PacmanLoader color='#2478DC' /></div>
    }

    if (!user) {
        return <Navigate to="/authentication/login" />;
    }else{
        return (
            <div className="ChapterManagement">
                <div className="navigation">
                    <button id="navigate" onClick={openNavbar}><FontAwesomeIcon icon={faBars} size="xl" /></button>
                </div>
                {navbarVisible && <Navbar toggleNavbar={closeNavbar} closing={navbarClosing} />}
                <h1>{chapterID ? 'تعديل محور' : 'أضف محور'}</h1>
                <form>
                    <div className="main">
                        <div className="textual">
                            <div>
                                <label> عنوان المحور </label>
                                <input
                                    type="text"
                                    placeholder="عنوان المحور"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
    
                                <label> وصف </label>
                                <div style={{ position: 'relative', width: '100%' }}>
                                    <textarea
                                        placeholder="وصف"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                        maxLength={maxChars}
                                    />
                                    <div className="char-count">{description ? (maxChars - description.length) + '/' + maxChars : '0/' + maxChars}</div>
                                </div>
                            </div>
                        </div>
                        <div className="line"></div>
                        <div className="media">
                            <label>الصورة {imagePerc > 0 && imagePerc + '%'}</label>
                            <div className="image-container">
                                <div>
                                    <input
                                        type="file"
                                        id="fileInput"
                                        style={{ display: 'none' }}
                                        accept="image/*"
                                        onChange={(e) => setSelectedImage(e.target.files[0])}
                                    />
                                    {imagePreview ? (
                                        <img
                                            src={imagePreview}
                                            alt="Uploaded"
                                            id="image-uploaded"
                                            onClick={handleImageClick}
                                        />
                                    ) : (
                                        <button id='image-input' type="button" onClick={handleImageClick}>تحميل</button>
                                    )}
                                </div>
                                <div>
                                    <h3>{title ? title : 'عنوان المحور'}</h3>
                                    <span>{description ? description : 'وصف للمحور'}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="buttons">
                        <button id="submit-button" type="submit" onClick={saveChapter}> حفظ </button>
                        <button
                            id="reset-button"
                            type="button"
                            onClick={resetFields}
                        > رجوع </button>
                    </div>
                </form>
            </div>
        );
    }
};

export default ChapterManagement;
