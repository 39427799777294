import '../scss/utils/Card.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faLock } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import React, { useState, useRef, useEffect } from 'react';
import EditOptionsModal2 from './EditOptionsModal2';
import { SERVER } from '../config/config';
import uncanny from '../utils/soundeffects/uncanny1.mp3'

const Card = ({ role, chapter, subject, onSubjectNotReadyClick }) => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const modalRef = useRef(null);
    const [animateNotReady, setAnimateNotReady] = useState(false);
    const audioRef = React.useRef(new Audio(uncanny));

    const handleNotReadyClick = () => {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        setAnimateNotReady(true);
        audioRef.current = new Audio(uncanny);
        audioRef.current.play().catch((err) => console.error("Audio playback failed:", err));
        setTimeout(() => setAnimateNotReady(false), 1000);
    };

    const handleSubjectClick = () => {
        if (subject && subject.ready) {
            localStorage.setItem('Subject', subject.title)
            navigate(`/chapters/${subject.ID}`);
        }else{
            onSubjectNotReadyClick()
        }
    };

    const handleChapterClick = () => {
        if (chapter && chapter.ready) {
            localStorage.setItem('Chapter', chapter.title)
            navigate(`/lessons/${chapter.subject_ID}/${chapter.ID}`);
        }else{
            handleNotReadyClick()
        }
    };

    const toggleModal = (event) => {
        event.stopPropagation();
        setShowModal(!showModal);
    };

    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            setShowModal(false);
        }
    };

    useEffect(() => {
        if (showModal) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showModal]);

    return subject ? (
        <div className='subject-card' onClick={handleSubjectClick}>
            {showModal && (
                <div ref={modalRef}>
                    <EditOptionsModal2 subject={subject} />
                </div>
            )}
            <div className='card-content'>
                <img src={SERVER+subject.img} alt={subject.title} />
                <h1>{subject.title}</h1>
            </div>
            { role !== 'user' && <button onClick={toggleModal}><FontAwesomeIcon icon={faEllipsisVertical} size='xl' /></button>}
        </div>
    ) : (
        <div className='chapter-card' onClick={handleChapterClick}>
            <img src={SERVER+chapter.img} alt={chapter.title} />
            <div>
                <h1>{chapter.title}</h1>
                <span className='custom-span'> {chapter.description} </span>
            </div>
            { role !== 'user' && <button onClick={toggleModal}><FontAwesomeIcon icon={faEllipsisVertical} size='xl' /></button>}
            {showModal && (
                <div ref={modalRef}>
                    <EditOptionsModal2 chapter={chapter} />
                </div>
            )}
            { !chapter.ready && <FontAwesomeIcon icon={faLock} className={animateNotReady ? 'locked animate' : 'locked'} />}
        </div>
    );
};

export default Card;
