import '../scss/utils/Info.scss'
import image_info from '../utils/info.png'
import image_confirm_info from '../utils/confirmInfo.png'

const Info = ({setAccept}) =>{
    return(
        <div className="Info">
            <h1> المستويات </h1>
            <span> يمكنك تصفح محتوى السنة التاسعة من التعليم الإعدادي سيتم إضافة بقية المستويات عند الإنتهاء من تطوير المحتوى الخاص بهم </span>
            <img src={image_info} alt='information utile' /> 
            <h1> قم بالضغط على الزر </h1>
            <button onClick={setAccept}><img src={image_confirm_info} alt='accept button' /></button>
        </div>
    )
}

export default Info;