import { Navigate, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faBookmark, faGear, faMedal } from "@fortawesome/free-solid-svg-icons";
import Navbar from "../../hooks/Navbar";
import '../../scss/pages/administration/Profile.scss'
import axios from "axios";
import { SERVER } from "../../config/config";
import EduMemeCard from "../../hooks/EduMemeCard";
import NotFound from "../../hooks/NotFound";
import ProgressCard from "../../hooks/ProgressCard";
import useAuth from "../../useAuth";
import { PacmanLoader } from 'react-spinners';
import { Helmet } from 'react-helmet';

const Profile = () => {
    const navigate = useNavigate();
    const { user, loading } = useAuth();   
    const [navbarVisible, setNavbarVisible] = useState(false);
    const [navbarClosing, setNavbarClosing] = useState(false);
    const [view, setView] = useState(false);

    const [level, setLevel] = useState('السّنة التّاسعة من التّعليم الأساسي');
    const [subjects, setSubjects] = useState([]);
    const [saved, setSaved] = useState([]);

    useEffect(() => {
        const fetchSubjects = async () => {
            try {
                const response = await axios.get(`${SERVER}/subjects/subjects`);
                setSubjects(response.data);
            } catch (error) {
                console.log(error);
            }
        };

        fetchSubjects();
    }, []);

    useEffect(() => {
        const fetchSaved = async () => {
            try {
                const response = await axios.get(`${SERVER}/interact/getSavedPerSubject`, {
                    params: {
                        userUID: user.ID,
                        subjects: subjects
                    }
                });

                if (response.status === 200) {
                    setSaved(response.data);
                }
            } catch (error) {
                console.log(error);
            }
        };
        if (subjects.length > 0 && user) {
            fetchSaved();
        }
    }, [subjects,user]);

    const openNavbar = () => {
        setNavbarClosing(false);
        setNavbarVisible(true);
    };

    const closeNavbar = () => {
        setNavbarClosing(true);
        setTimeout(() => {
            setNavbarVisible(false);
        }, 500);
    };

    const settings = () => {
        navigate('/EditProfile');
    }

    const displaySaved = () => {
        setView(false);
    };

    const displayAchievements = () => {
        setView(true);
    };

    const groupBySubject = (edumemes) => {
        return edumemes.reduce((acc, edumeme) => {
            if (!acc[edumeme.subjectID]) {
                acc[edumeme.subjectID] = [];
            }
            acc[edumeme.subjectID] = edumeme;
            return acc;
        }, {});
    };

    const groupedEduMemes = groupBySubject(saved);
    
    // Create a mapping of matiereID to subject name
    const subjectMap = subjects.reduce((acc, subject) => {
        acc[subject.ID] = subject;
        return acc;
    }, {});

    if (loading) {
        return <div style={{minHeight:'100vh', width:'100%', display: 'flex', justifyContent:'center', alignItems: 'center'}}><PacmanLoader color='#2478DC' /></div>
    }

    if(!user){
        return <Navigate to="/authentication/login" />;
    }else{
        return (
            <div className='Profile'>
                <Helmet>
                    <title>الملف الشخصي</title>
                    <meta name="description" content="عرض وإدارة الملف الشخصي الخاص بك على منصة الميم و التعليم." />
                    <meta property="og:title" content="الملف الشخصي - الميم و التعليم" />
                    <meta property="og:description" content="عرض وإدارة الملف الشخصي الخاص بك على منصة الميم و التعليم." />
                    <meta property="og:image" content="https://edu.memes.tn/preview.png" />
                    <meta property="og:url" content="https://edu.memes.tn/Profile" />
                    <meta property="og:type" content="website" />
                    <meta property="og:locale" content="ar_TN" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="الملف الشخصي - الميم و التعليم" />
                    <meta name="twitter:description" content="عرض وإدارة الملف الشخصي الخاص بك على منصة الميم و التعليم." />
                    <meta name="twitter:image" content="https://edu.memes.tn/preview.png" />
                    <link rel="canonical" href="https://edu.memes.tn/Profile" />
                </Helmet>
                <div className="navigation">
                    <button id="settings"><FontAwesomeIcon icon={faGear} size="xl" onClick={settings} /></button>
                    <button id="navigate" onClick={openNavbar}><FontAwesomeIcon icon={faBars} size="xl" /></button>
                </div>
                {navbarVisible && <Navbar toggleNavbar={closeNavbar} closing={navbarClosing} />}
    
                <h1>مرحبا بك!</h1>
                <h2>{user && user.name}</h2>
                <h4 id='username'> {user && user.username}@ </h4>
                <h5> المستوى: {level} </h5>
    
                <div className="main">
                    <div className="headers">
                        <div className="buttons">
                            <div>
                                <button onClick={displaySaved}><FontAwesomeIcon icon={faBookmark} size="2xl" /></button>
                                {view === false ? <hr className="active"></hr> : <></>}
                            </div>
                            <div>
                                <button onClick={displayAchievements}><FontAwesomeIcon icon={faMedal} size="2xl" /></button>
                                {view === true ? <hr className="active"></hr> : <></>}
                            </div>
                        </div>
                        <hr />
                    </div>
                    <div className="main-section">
                        {view === false ? (
                            <div className="saved">
                                {Object.keys(groupedEduMemes).length > 0 ? (
                                    Object.entries(groupedEduMemes).map(([subjectID, edumemes], idx) => (
                                        <div key={'Cluster' + idx} className="subject-section">
                                            <h2 key={'subject' + idx}>{subjectMap[subjectID].title || "Unknown Subject"}</h2>
                                            <div className="edumemes">
                                                {edumemes.savedEduMemes.length > 0 ? (
                                                    edumemes.savedEduMemes.map((edumeme, edumemeIdx) => (
                                                        <EduMemeCard key={'EduMeme' + edumemeIdx} edumeme={edumeme} role={user && user.role} saved={edumemes.savedEduMemes} subject={subjectMap[subjectID]} clicked_index={edumemeIdx} />
                                                    ))
                                                ) : (
                                                    <div className="notfound-edumemes">
                                                        <NotFound role={user && user.role} />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className="notfound-edumemes">
                                        <NotFound role={user && user.role} />
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className="progress-container">
                                {subjects && subjects.map((subject, idx) => (
                                    <ProgressCard key={idx} user={user} subject={subject} />
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default Profile;
