import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from './useAuth';
import { PacmanLoader } from 'react-spinners';

const ProtectedRoute = ({ element: Component, allowedRoles }) => {
  let isAuthenticated = false;
  let role = undefined;
  const {user, loading} = useAuth();
  if(loading){
      return <div style={{minHeight:'100vh', width:'100%', display: 'flex', justifyContent:'center', alignItems: 'center'}}><PacmanLoader color='#2478DC' /></div>
  }else if (!loading && user) {
    return Component;
  }

  if (!allowedRoles.includes(role)) {
    // Redirect to a "Not Allowed" page if the user does not have the required role
    return <Navigate to="/not-allowed" />;
  }

  if (!isAuthenticated) {
    // Redirect to the login page if not authenticated
    return <Navigate to="/authentication/login" />;
  }

  // Render the protected component if the user is authenticated and authorized
  
};

export default ProtectedRoute;
