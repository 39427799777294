// src/pages/Authentication.js

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import '../../scss/pages/login.scss';
import logo from '../../utils/logowhite.png';
import logoMobile from '../../utils/logo1.png';
import { SERVER } from "../../config/config";
import { Navigate, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import cannyAudio from '../../utils/soundeffects/canny.mp3'
import uncanny1Audio from '../../utils/soundeffects/uncanny1.mp3'
import uncanny2Audio from '../../utils/soundeffects/uncanny3.mp3'
import uncanny3Audio from '../../utils/soundeffects/uncanny4.mp3'
import uncanny4Audio from '../../utils/soundeffects/uncanny5.mp3'
import uncanny5Audio from '../../utils/soundeffects/uncanny6.mp3'
import uncanny1 from '../../utils/mrincredible/canny.png'
import uncanny2 from '../../utils/mrincredible/sad2.png'
import uncanny3 from '../../utils/mrincredible/sad3.png'
import uncanny4 from '../../utils/mrincredible/sad4.png'
import uncanny5 from '../../utils/mrincredible/sad5.png'
import uncanny6 from '../../utils/mrincredible/sad6.png'
import superCanny from '../../utils/mrincredible/supercanny.png'
import useAuth from '../../useAuth';
import Cookies from 'js-cookie';
import { PacmanLoader } from 'react-spinners';
import { Helmet } from 'react-helmet';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Authentication = () => {
    const navigate = useNavigate();
    const { type } = useParams();
    const MrIncredibleImages = [uncanny1, uncanny2, uncanny3, uncanny4, uncanny5, uncanny6]
    const [imageFade, setImageFade] = useState(false);
    const MrIncredibleAudio = [uncanny1Audio, uncanny2Audio, uncanny3Audio, uncanny4Audio, uncanny5Audio]
    const loginMessages = ['ربما قمت بكتابة بيانات حسابك بشكل خاطئ.'
                                              ,'نعم، لقد قمت بإدخال بيانات حسابك بشكل خاطئ.'
                                              ,'قد قمت بإدخال بيانات حسابك بشكل خاطئ للمرّة عدد 3'
                                              ,'قد قمت بإدخال بيانات حسابك بشكل خاطئ للمرّة عدد 4'
                                              ,'قد قمت بإدخال بيانات حسابك بشكل خاطئ للمرّة عدد ∞']
    const [messageLogin, setMessageLogin] = useState('مرحبا بك 👋🏼') 
    const [loginSuccess, setLoginSuccess] = useState(false)
    const [cannyIndex, setCannyIndex] = useState(0)
    const [isChecked, setIsChecked] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showlogin, setShowLogin] = useState(type === 'login');
    const [showSignUp, setShowSignUp] = useState(type === 'signup');
    const [pageOne, setPageOne] = useState(false)
    const [resetPassword, setResetPassword] = useState(type === 'forgotPassword')
    const [emailLogin, setEmailLogin] = useState('');
    const [passwordLogin, setPasswordLogin] = useState('');

    const [emailSignUp, setEmailSignUp] = useState('');
    const [passwordSignUp, setPasswordSignUp] = useState('');
    const [username, setUsername] = useState('');
    const [confirmLink ,setConfirmLink] = useState(false)
    const [resetEmail, setResetEmail] = useState('')
    const [signUpError, setSignUpError] = useState('')
    const [confirmEmailSent, setConfirmEmailSent] = useState('');
    const [emailStatus, setEmailStatus] = useState(false)
    const [isAudioAllowed, setIsAudioAllowed] = useState(false);
    const audioRef = React.useRef(new Audio(cannyAudio));
    const {user, loading} = useAuth()
    
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        // Function to check screen width
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        // Set initial screen width
        handleResize();

        // Listen for window resize events
        window.addEventListener("resize", handleResize);

        // Cleanup the event listener on component unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        const handleUserInteraction = () => {
            setIsAudioAllowed(true);
            window.removeEventListener('click', handleUserInteraction);
            window.removeEventListener('touchstart', handleUserInteraction);
        };

        // Listen for user interaction
        window.addEventListener('click', handleUserInteraction);
        window.addEventListener('touchstart', handleUserInteraction);

        return () => {
            window.removeEventListener('click', handleUserInteraction);
            window.removeEventListener('touchstart', handleUserInteraction);
        };
    }, []);

    useEffect(() => {
        if (isAudioAllowed) {
            audioRef.current.play().catch((error) => {
                console.error("Audio playback failed:", error);
            });
        }
    }, [isAudioAllowed]);

    const handleCheckboxChange = () => {
        setIsChecked((prevState) => !prevState);
    };

    const togglePasswordVisibility = () => {
        setShowPassword((prevState) => !prevState);
    };

    const createAccount = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${SERVER}/auth/register`, {
                email: emailSignUp,
                password: passwordSignUp,
                username: username
            });

            if (response.status === 201) {
                toast.success("تم إرسال بريد تأكيد الحساب إلى عنوان بريدك الإلكتروني!");
                setTimeout(() => {
                    navigate('/authentication/login');
                    window.location.reload();
                }, 3000);
            }
        } catch (error) {
            // Check if there's a server response and set the error message accordingly
            if (error.response && error.response.data && error.response.data.message) {
                // Set the error message from the server
                setSignUpError(error.response.data.message);
            } else {
                // Fallback to a general error message if no specific one is provided
                setSignUpError('حدث خطأ أثناء التسجيل. يرجى المحاولة مرة أخرى.');
            }
            console.error('Signup Error:', error);
        }
    };
    
    const login = async (e) => {
        e.preventDefault();
        setConfirmLink(false)
        try {
            const response = await axios.post(`${SERVER}/auth/login`, {
                usernameOrEmail: emailLogin,
                password: passwordLogin
            });
    
            if (response.status === 200) {
                if (response.data.last_login === true){
                    localStorage.setItem('first_login', true)
                }
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
                setLoginSuccess(true)
                Cookies.set('token', response.data.token, { expires: 1 });
                setMessageLogin('مرحبًا، نحن سعداء بوجودك معنا.')
                setTimeout(() => {
                    if (response.data.user.role === 'admin') {
                        navigate('/subjects');
                    } else {
                        navigate('/subjects');
                    }
                }, 2000);
            }
        } catch (error) {
            console.error(error);
            if (error.response.status === 402){
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
                setImageFade(true);
                setMessageLogin('يبدو أنك لم تقم بتأكيد بريدك الإلكتروني، انقر على الرابط أدناه لإرسال بريد تأكيد لك.')
                setConfirmLink(true)
                setTimeout(() => {
                    setImageFade(false);
                }, 450);
    
                audioRef.current = new Audio(MrIncredibleAudio[cannyIndex]);
                audioRef.current.play().catch((err) => console.error("Audio playback failed:", err));
            }else{            
                if (cannyIndex < MrIncredibleImages.length - 1) {
                    audioRef.current.pause();
                    audioRef.current.currentTime = 0;
                    setImageFade(true);
                    setMessageLogin(loginMessages[cannyIndex])
                    setTimeout(() => {
                        setCannyIndex(prevIndex => prevIndex + 1);
                        setImageFade(false);
                    }, 450);
        
                    audioRef.current = new Audio(MrIncredibleAudio[cannyIndex]);
                    audioRef.current.play().catch((err) => console.error("Audio playback failed:", err));
                }
            }
        }
    };    

    const sendEmailLink = async () =>{
        try{
            const response = await axios.post(`${SERVER}/auth/confirm-email-link`, {email: emailLogin})
            if(response.status === 201){
                toast.success("تم إرسال بريد تأكيد الحساب إلى عنوان بريدك الإلكتروني!");
                setTimeout(() => {
                    setConfirmLink(false)
                    window.location.reload();
                }, 3000);
            }else{
                toast.error("البريد الإلكتروني غير موجود، تحقق من عنوان بريدك الإلكتروني!");
            }
        }catch(err){
            console.log(err)
        }
    }

    const sendConfirmEmail = async () =>{
        try{
            const response = await axios.post(`${SERVER}/auth/forgot-password`, {email: resetEmail})
            if(response.status === 200){
                setConfirmEmailSent('رابط إعادة تعيين كلمة المرور تم إرساله إلى بريدك الإلكتروني')
                setEmailStatus(true)
            }else{
                setConfirmEmailSent("البريد الإلكتروني غير موجود، تحقق من عنوان بريدك الإلكتروني")
                setEmailStatus(false)
            }
        }catch(err){
            console.log(err)
        }
    }

    const verifyEmail = async (email) =>{
        try{
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(emailSignUp)) {
                setSignUpError('البريد الإلكتروني غير صحيح')
                return true
            }

            const response = await axios.get(`${SERVER}/auth/verifyEmail`, {
                params : {
                    email : emailSignUp
                }
            })
            
            if (!response.data.exist){
                return false
            }else{
                setSignUpError('هذا البريد الإلكتروني موجود بالفعل')
                return true
            }
        }catch(err){
            console.log(err)
        }
    }

    if (loading) {
        return <div style={{minHeight:'100vh', width:'100%', display: 'flex', justifyContent:'center', alignItems: 'center'}}><PacmanLoader color='#2478DC' /></div>
    }
    else if (user){
        return <Navigate to="/subjects" />;
    }else{
        return (
            <div className="auth">
                <ToastContainer position="top-center" autoClose={3000} />
                <Helmet>
                    <title>تسجيل الدخول</title>
                    <meta name="description" content="قم بتسجيل الدخول للوصول إلى حسابك على منصة الميم و التعليم." />
                    <meta property="og:title" content="تسجيل الدخول - الميم و التعليم" />
                    <meta property="og:description" content="قم بتسجيل الدخول للوصول إلى حسابك على منصة الميم و التعليم." />
                    <meta property="og:image" content="https://edu.memes.tn/preview.png" />
                    <meta property="og:url" content="https://edu.memes.tn/authentication" />
                    <meta property="og:type" content="website" />
                    <meta property="og:locale" content="ar_TN" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="تسجيل الدخول - الميم و التعليم" />
                    <meta name="twitter:description" content="قم بتسجيل الدخول للوصول إلى حسابك على منصة الميم و التعليم." />
                    <meta name="twitter:image" content="https://edu.memes.tn/preview.png" />
                    <link rel="canonical" href="https://edu.memes.tn/authentication" />
                </Helmet>
                <div className='auth-container'>
                    <img src={logoMobile} alt="Logo" className='mobile-logo' />
                    <div className={showlogin ? 'login-container' : 'hidden-login'}>
                        <h1>تسجـيل الدخول</h1>
                        {isMobile && (
                            <div className='design-mobile'>
                                <span style={loginSuccess === false && cannyIndex !== 0 || confirmLink === true ? {color: 'red'} : {color: '#2478DC'}}> {messageLogin} </span>
                                {confirmLink && <button style={{marginTop: '2.5%'}} onClick={sendEmailLink} disabled={!confirmLink}>إرسال بريد تأكيد</button>}
                            </div>
                        )}
                        <form>
                            <label>البريد الإلكتروني</label>
                            <input type='email' placeholder='البريد الإلكتروني' onChange={(e) => setEmailLogin(e.target.value)} required />
                            <label style={{ marginTop: '30px' }}>كلمة المرور</label>
                            <div className="password-input">
                                <input type={showPassword ? 'text' : 'password'} placeholder='كلمة المرور' onChange={(e) => setPasswordLogin(e.target.value)} required />
                                <FontAwesomeIcon
                                    icon={showPassword ? faEye : faEyeSlash}
                                    onClick={togglePasswordVisibility}
                                    color='#2478DC'
                                    size='xs'
                                />
                            </div>
                            <div className='forget-pwd'>
                                <div className='rememberme'>
                                    <input
                                        type="checkbox"
                                        id="checkbox"
                                        checked={isChecked}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label>تذكرني</label>
                                </div>
                                <a href='/authentication/forgotPassword'>نسيت كلمة المرور؟</a>
                            </div>
                            <button id='submit-login' onClick={login}>تسجيل الدخول</button>
                            <button id='create' type='button' onClick={() => {
                                navigate('/authentication/signup')
                                window.location.reload()
                            }}>إنشاء حساب</button>
                        </form>
                    </div>
                    <div className={showSignUp ? 'signup-container' : 'hidden-signup'}>
                        <h1>إنشاء حساب</h1>
                        <span> {signUpError} </span>
                        <div className="dots">
                            <div className={`dot ${pageOne === true ? 'active' : ''}`} onClick={() => setPageOne(true)}></div>
                            <div className={`dot ${pageOne === false ? 'active' : ''}`} onClick={() => setPageOne(false)}></div>
                        </div>
                        <div className='page1' style={{ display: pageOne ? 'none' : 'flex' }}>
                            <form>
                                <label>البريد الإلكتروني</label>
                                <input type='email' placeholder='البريد الإلكتروني' required
                                    onChange={(e) => {
                                        setSignUpError('')
                                        setEmailSignUp(e.target.value);
                                    }} 
                                />
                                <label style={{ marginTop: '30px' }}>كلمة المرور</label>
                                <div className="password-input">
                                    <input type={showPassword ? 'text' : 'password'} placeholder='كلمة المرور' 
                                        onChange={(e) => {
                                            setSignUpError('')
                                            setPasswordSignUp(e.target.value)
                                        }} required />
                                    <FontAwesomeIcon
                                        icon={showPassword ? faEye : faEyeSlash}
                                        onClick={togglePasswordVisibility}
                                        color='#2478DC'
                                        size='xs'
                                    />
                                </div>
                                <button id='submit-signup' type='button' onClick={ async () => {
                                    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[0-9]).{6,}$/;
                                    const emailVerif = await verifyEmail(emailSignUp)
                                    if (emailVerif) {
                                        console.log('error')
                                    } else if (!passwordRegex.test(passwordSignUp)) {
                                        setSignUpError('كلمة المرور يجب أن تحتوي على أحرف كبيرة وصغيرة وأرقام، ويجب أن تكون مكونة من 8 أحرف على الأقل.');
                                    } else {
                                        setPageOne(true);
                                    }
                                }}>
                                التالي
                                </button>

                                <button id='login' type='button' onClick={() => {
                                    navigate('/authentication/login')
                                    window.location.reload()
                                }}>أملك حساب</button>
                            </form>
                        </div>
                        <div className='page2' style={{ display: pageOne ? 'flex' : 'none' }}>
                            <div>
                                <label>إسم المستخدم</label>
                                <input 
                                    type='text' 
                                    placeholder='إسم المستخدم' 
                                    onChange={(e) => setUsername(e.target.value)} 
                                    required 
                                />
                                <button id='final-submit' onClick={(e) => {
                                    const usernameRegex = /^[a-zA-Z0-9_-]{3,}$/;
                                    if (!usernameRegex.test(username)) {
                                        setSignUpError('اسم المستخدم يجب أن يحتوي فقط على أحرف لاتينية وأرقام وشرطات سفلية وشرطات عادية');
                                    } else {
                                        createAccount(e);
                                    }
                                }}>إنشاء حساب</button>
                            </div>
                        </div>
                    </div>
    
                    <div className={resetPassword ? 'signup-container' : 'hidden-reset'}>
                        <h1>إعادة تعيين كلمة المرور</h1>
                        <div className='page1'>
                            <form>
                                <label>البريد الإلكتروني</label>
                                <input type='email' placeholder='البريد الإلكتروني' onChange={(e) => setResetEmail(e.target.value)} />
                                <button id='submit-reset' type='button' onClick={() => sendConfirmEmail(true)}>تأكيد</button>
                                {confirmEmailSent !== '' && emailStatus ? 
                                    (<span className='emailSent' style={{color: 'green'}}> {confirmEmailSent} </span>)
                                    : (<span className='emailSent' style={{color: 'red'}}> {confirmEmailSent} </span>)
                                }
                                
                            </form>
                        </div>
                    </div>
                </div>
                <div className="design">
                    <div className='logo'>
                        <img src={logo} alt="Logo" />
                        <h2>الميم و التعليم</h2>
                    </div>
                    <img src={loginSuccess ? superCanny : MrIncredibleImages[cannyIndex]} className={imageFade ? 'animate' : ''} alt="MrIncredible" />
                    <span style={loginSuccess === false && cannyIndex !== 0 ? {color: 'red'} : {color: '#F4F5F9'}}> {messageLogin} </span>
                    {confirmLink && <button style={{marginTop: '2.5%'}} onClick={sendEmailLink}>إرسال بريد تأكيد</button>}
                </div>
            </div>
        );
    }
}

export default Authentication;
