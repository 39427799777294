// EduMemeCard.jsx
import '../scss/utils/EduMemeCard.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faLock } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useState, useRef, useEffect } from 'react';
import EditOptionsModal from './EditOptionsModal';

const EduMemeCard = ({ role, edumeme, saved, subject, clicked_index }) => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const modalRef = useRef(null);

    const visitEdumeme = () => {
        if (saved){
            navigate(`/savedEduMemes/${subject.ID}`, { state: { edumemes: saved, subject: subject, clicked: clicked_index } });    
        }else if (edumeme.ready){
            localStorage.setItem('edumeme', edumeme.ID);
            navigate(`/edumemes/${edumeme.lesson_ID}`);    
        }
    };

    const toggleModal = (event) => {
        event.stopPropagation();
        setShowModal(!showModal);
    };

    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            setShowModal(false);
        }
    };

    useEffect(() => {
        if (showModal) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showModal]);

    return (
        <div className="edumeme-card" onClick={visitEdumeme}>
            {!edumeme.ready && (
                <FontAwesomeIcon className='ready-edumeme' icon={faLock} />
            )}
            { role !== 'user' && 
                <button onClick={toggleModal}>
                    <FontAwesomeIcon icon={faEllipsisVertical} size='lg' color='white' />
                </button>
            }
            <h2>{edumeme.title}</h2>
            <span>{edumeme.subtitle}</span>
            {showModal && (
                <div ref={modalRef}>
                    <EditOptionsModal edumeme={edumeme} />
                </div>
            )}
        </div>
    );
};

export default EduMemeCard;
