import axios from "axios";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PacmanLoader } from "react-spinners";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SERVER } from "../../config/config";

const ConfirmEmail = () => {
    const { confirmToken } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const confirmEmail = async () => {
            try {
                const response = await axios.post(`${SERVER}/auth/confirm-email/${confirmToken}`);
                if (response.status === 200) {
                    // Display confirmation message in Arabic
                    toast.success("تم تأكيد البريد الإلكتروني بنجاح!");
                    // Wait 3 seconds before redirecting
                    setTimeout(() => {
                        navigate('/authentication/login');
                    }, 3000);
                } else {
                    navigate('/TokenNotValid');
                }
            } catch (err) {
                console.log(err);
                navigate('/TokenNotValid');
            }
        };

        if (confirmToken) {
            confirmEmail();
        }
    }, [confirmToken, navigate]);

    return (
        <div style={{ minHeight: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <PacmanLoader color='#2478DC' />
            <ToastContainer position="top-center" autoClose={3000} />
        </div>
    );
};

export default ConfirmEmail;
