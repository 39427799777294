import { useNavigate } from 'react-router-dom';
import '../../scss/pages/administration/AccountSetForRemoval.scss'
import sadCat from '../../utils/sadCat.jpg';
import { Helmet } from 'react-helmet';

const AccountSetForRemoval = () =>{
    const navigate = useNavigate();
    return(
        <div className="AccountSetForRemoval">
            <h1> تم إعداد حسابك للإزالة.</h1>
            <span> يرجى ملاحظة أنه سيتم حذف حسابك بعد شهر واحد. يمكنك استعادة حسابك إذا قمت بتسجيل الدخول قبل مرور 30 يومًا.</span>
            <span> وإلا سيتم حذف الحساب بشكل دائم </span>
            <img src={sadCat} alt='sad' />
            <span> شكرًا لك.</span>
            <button onClick={() => navigate('/')}>العودة إلى الصفحة الرئيسية</button>
        </div>
    )
}

export default AccountSetForRemoval;