import HomeNavbar from '../../hooks/HomeNavbar';
import '../../scss/pages/Home/Home.scss'
import logo from '../../utils/logo1.png'; 
import intro from '../../utils/intro.png'
import creation from '../../utils/edumemesCreation.png'
import mobile from '../../utils/mobile.png'
import desktop from '../../utils/desktop.png'
import ground from '../../utils/ground.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faTiktok, faYoutube, faGooglePlay, faApple } from '@fortawesome/free-brands-svg-icons';
import { useNavigate } from 'react-router-dom';
import Footer from '../../hooks/Footer';
import appStore from '../../utils/appstore.svg'
import playStore from '../../utils/playstore.svg'
import meme1 from '../../utils/meme1.png'
import meme2 from '../../utils/meme2.png'
import { Helmet } from 'react-helmet';

const Home = () =>{
    const navigate = useNavigate();

    const visitSite = (link, type) =>{
        window.open(link, type);
    }

    return(
      <div className='Home'>
        <Helmet>
            <meta charset="utf-8" />
            <link rel="icon" href="https://edu.memes.tn/favicon.ico" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="theme-color" content="#000000" />
            <meta name="description" content="الميم والتعليم هو تطبيق تعليمي ممتع لتلاميذ التّعليم الإعدادي و الثّانوي. يقدّم المعلومات من خلال الميمز و التّعديلات لتسهيل الفهم." />
            <link rel="apple-touch-icon" href="https://edu.memes.tn/preview.png" />
            <link rel="manifest" href="https://edu.memes.tn/manifest.json" />
            <link rel="canonical" href="https://edu.memes.tn" />
            <title>الميم و التعليم</title>
            <meta name="keywords" content="التعليم، الميم، التعليم الثانوي، التعليم الإعدادي، ميمز، مواد دراسية، تعلم ممتع، تلاميذ، تونس, memes, education, tunisia, tunis, fun, edumemes, edu, meme, lycee, college" />
            <meta property="og:title" content="موقع الميم و التعليم" />
            <meta property="og:description" content="الميم والتعليم هو تطبيق تعليمي ممتع لتلاميذ التعليم الإعدادي والثانوي، يعتمد على الميمز والتعديلات لتسهيل الفهم." />
            <meta property="og:image" content="https://edu.memes.tn/preview.png" />
            <meta property="og:url" content="https://edu.memes.tn/" />
            <meta property="og:type" content="website" />
            <meta property="og:locale" content="ar_TN" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="موقع الميم و التعليم" />
            <meta name="twitter:description" content="الميم والتعليم هو تطبيق تعليمي ممتع لتلاميذ التعليم الإعدادي والثانوي، يعتمد على الميمز والتعديلات لتسهيل الفهم."  />
            <meta name="twitter:image" content="https://edu.memes.tn/preview.png" />
            <meta name="robots" content="index, follow" />
        </Helmet>
          <HomeNavbar />
          <div className='intro'>
              <img src={logo} />
              <h1> التّطبيق الذي لا يفرّق بين التّعلم والمتعة </h1>
              <img id='intro-image' src={intro} />
              <button onClick={() => {
                  const targetElement = document.querySelector('#get-started');
                  const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY - 200;
                  window.scrollTo({
                      top: targetPosition,
                      behavior: 'smooth'
                  });
              }}>
                  ابدأ الآن
              </button>
          </div>
          <div className='home-about-us'>
              <h1> ماهو تطبيق الميم والتعليم </h1>
              <div className='description'>
                  <ul>
                    <li> الميم والتعليم هو تطبيق تعليمي موجّه لتلاميذ التّعليم الإعدادي و الثّانوي. </li>
                    <li> يعتمد على الميمز و التّعديلات لإيصال المعلومة بطريقة ممتعة.</li>
                    <li>يعتمد التّطبيق أيضا على المحتوى القصير والتصميم العملي لضمان تجربة استخدام خالية من الملل عند الدراسة.</li>
                    <button onClick={() => visitSite('/AboutUs', '_self')}> تعرّف أكثر </button>
                  </ul>
                  <div className='ppf'></div>
              </div>
              <img src={creation} />
          </div>
          <div className='availability'>
              <h1> التّطبيق متوفّر الآن على مختلف المنصّات </h1>
              <div className='mobdesk'>
                  <div>
                      <img src={mobile} id='mobile' />
                      <img src={desktop} id='desktop' />
                  </div>
                  <img src={ground} id='ground' />
              </div>
          </div>
          <div className='get-started' id='get-started'>
              <div className='start-mobile'>
                  <h1> تطبيق الهاتف متوفّر على متجر الأندرويد والآيفون </h1>
                  <div className='start-buttons'>
                        <button onClick={() => visitSite('https://apps.apple.com/tn/app/edu-memes-%D8%A7%D9%84%D9%85%D9%8A%D9%85-%D9%88-%D8%A7%D9%84%D8%AA%D8%B9%D9%84%D9%8A%D9%85/id6736692795', '_blank')}> 
                            <img src={appStore} />
                        </button>
                        <button onClick={() => visitSite('https://play.google.com/store/apps/details?id=com.guitouni.edumemes', '_blank')}> 
                            <img src={playStore} />
                        </button>
                  </div>
              </div>
              <div className='start-desktop'>
                  <h1> يمكنك استعمال تطبيق الويب ايضا </h1>
                  <div className='start-buttons'>
                      <button onClick={() => navigate('/authentication/login')}> تسجيل الدخول </button>
                      <button onClick={() => navigate('/authentication/signup')}> إنشاء حساب </button>
                  </div>
              </div>
          </div>
          <div className="objectif">
              <h1>ما هو هدفنا؟</h1>
              <h3>لماذا نقضي ساعات في مواقع التواصل الاجتماعي أو في اللعب و لا نشعر بالوقت؟ لكن عندما
                  نحاول المراجعة، نشعر ببطئ كل دقيقة تمر؟
              </h3>
              <div className="memes">
                <div className="meme">
                  <img src={meme1} />
                </div>
                <div className="meme">
                  <img src={meme2} />
                </div>
              </div>
              <h1 id='our-goal' >هدفنا ببساطة هو جعل المراجعة تجربة
                  ممتعة للتلميذ
              </h1>
          </div>

          <div id="quote">
            <h1>
              "لا تفرق بين العمل واللعب. اعتبر كل ما تفعله بمثابة لعب، ولا تتخيل ولو لدقيقة واحدة أنك يجب أن تكون جاداً"  
            </h1>
            <h1>
              آلان واتس 
            </h1>
          </div>

          <div className="social-media">
            <h1>تابعنا على منصات التواصل الاجتماعي</h1>
            <h3>نقوم بنشر الميمز المتعلقة بالتلاميذ و التعليم</h3>
            <div className="icons">
              <a href="https://www.instagram.com/edu.memes.tn/" target='_blank'><FontAwesomeIcon icon={faInstagram} style={{color: "#F4F5F9",}} /></a>
              <a href="https://www.facebook.com/edu.memes.tn/" target='_blank'><FontAwesomeIcon icon={faFacebookF} style={{color: "#F4F5F9",}} /></a>
              <a href="https://www.tiktok.com/@edu.memes.tn" target='_blank'><FontAwesomeIcon icon={faTiktok} style={{color: "#F4F5F9",}} /></a>
              <a href="https://www.youtube.com/@edu-memes-tn" target='_blank'><FontAwesomeIcon icon={faYoutube} style={{color: "#F4F5F9",}} /></a>
            </div>
          </div>
          <Footer />
      </div>
    )
}

export default Home;