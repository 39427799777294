import { useLocation, useNavigate } from 'react-router-dom';
import {useState, useEffect, useRef} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleLeft, faHeart, faBookmark, faCircleChevronUp, faCircleChevronDown, faLink, faChartSimple, faComment, faChevronLeft, faBars, faCircleArrowLeft } from '@fortawesome/free-solid-svg-icons';
import useAuth from '../useAuth';
import { PacmanLoader } from 'react-spinners';
import axios from 'axios';
import { SERVER } from '../config/config';
import EduMemeCardScroll from '../hooks/EduMemeCardScroll';
import Comment from '../hooks/Comment';
import extremelycanny from '../utils/mrincredible/extremelycanny.png'
import stats from '../utils/stats.png'
import loop from '../utils/loop.png'
import send from '../utils/others/send.png';
import { Helmet } from 'react-helmet';

const SavedEduMemes = () =>{
    const {user, loading} = useAuth()
    const navigate = useNavigate()
    const location = useLocation();
    const [subject, setSubject] = useState(location.state?.subject || {});
    const [chapter, setChapter] = useState()
    const [lesson, setLesson] = useState()
    const [eduMemes, setEduMemes] = useState(location.state?.edumemes || []);
    const [viewStyle, setViewStyle] = useState(window.innerWidth <= 768 ? 'Scrolling' : 'Swiping');
    const [iconSize, setIconSize] = useState('xl');
    const [animationClass, setAnimationClass] = useState('');
    const [currentMemeIndex, setCurrentMemeIndex] = useState(location.state?.clicked ?? 0);
    const [currentMeme, setCurrentMeme] = useState(eduMemes[currentMemeIndex]);
    const [comment, setComment] = useState('');

    const [liked, setLiked] = useState(false);
    const [comments, setComments] = useState([]);
    const [saved, setSaved] = useState(false);

    const [view,setView] = useState(true)
    const [statistics, setStatistics] = useState([])
    const [touchStartY, setTouchStartY] = useState(0);
    const [isAnimating, setIsAnimating] = useState(false);
    const [openCommentSection, setOpenCommentSection] = useState(false)
    const [openStaticticSection, setOpenStatisticSection] = useState(false)
    const [mediaAnimationClass, setMediaAnimationClass] = useState('');
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [showInformations, setShowInformations] = useState(true);

    const handleVideoClick = () => {
        if (isPlaying) {
            videoRef.current.pause();
        } else {
            videoRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const handleNextMeme = () => {
        if (currentMemeIndex < eduMemes.length - 1) {
            setAnimationClass('slide-out-up');
            setTimeout(() => {
                setCurrentMemeIndex(currentMemeIndex + 1);
                setCurrentMeme(eduMemes[currentMemeIndex + 1])
                setAnimationClass('slide-in-up');
            }, 500);
        }
    };

    const handlePreviousMeme = () => {
        if (currentMemeIndex > 0) {
            setAnimationClass('slide-out-down');
            setTimeout(() => {
                setCurrentMemeIndex(currentMemeIndex - 1);
                setCurrentMeme(eduMemes[currentMemeIndex - 1])
                setAnimationClass('slide-in-down');
            }, 500);
        }
    };

    const displayStatistics = () =>{
        getStatistics()
        setView(false)
    }   
    const displayComments = () =>{
        setView(true)
    }

    const handleLike = async (e, edumeme, liked) => {
        e.preventDefault();
        try {
            if (liked){
                const response = await axios.delete(`${SERVER}/interact/dislike`, {
                params : {
                    edumemeID: edumeme.ID,
                    userUID: user.ID
                }});
    
                if (response.status === 200) {
                    return false
                }
            }else{
                const response = await axios.post(`${SERVER}/interact/like`, {
                    edumemeID : edumeme.ID,
                    userUID: user.ID
                });
    
                if (response.status === 201) {
                    return true
                }
            }
            
        } catch (e) {
            console.log(e);
        }
    }

    const addComment = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${SERVER}/interact/comment`, {
                edumemeID : currentMeme.ID,
                userUID : user.ID,
                comment
            });
            if (response.status === 201) {
                setComments([response.data, ...comments]);
                setComment('');
            }
        } catch (e) {
            console.log(e);
        }
    }

    const handleCommentKeyDown = async (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (comment.trim() === '') {
                return; // Do not add empty comments
            }
            try {
                const response = await axios.post(`${SERVER}/interact/comment`, {
                    edumemeID : currentMeme.ID,
                    userUID : user.ID,
                    comment
                });
    
                if (response.status === 201) {
                    setComments([response.data, ...comments]);
                    setComment('');
                }
            } catch (e) {
                console.log(e);
            }
        }
    };

    const handleSave = async (e, edumeme, saved) => {
        e.preventDefault();
        try {
            if (saved) {
                const response = await axios.delete(`${SERVER}/interact/unsave`, {
                    params: {
                        edumemeID: edumeme.ID,
                        userUID: user.ID
                    }
                });
    
                if (response.status === 200) {
                    const updatedEduMemes = eduMemes.filter(eduM => eduM.ID !== edumeme.ID);
                    
                    setEduMemes(updatedEduMemes);
                    if (location.state) {
                        location.state.edumemes = updatedEduMemes;
                    }
    
                    if (updatedEduMemes.length > 0) {
                        setCurrentMeme(updatedEduMemes[0]);
                        setCurrentMemeIndex(0);
                    } else {
                        setCurrentMeme(null);
                        setCurrentMemeIndex(null);
                        navigate('/Profile')
                    }
    
                    return false;
                }
            } else {
                const response = await axios.post(`${SERVER}/interact/save`, {
                    edumemeID: edumeme.ID,
                    userUID: user.ID
                });
    
                if (response.status === 201) {
                    return true;
                }
            }
        } catch (e) {
            console.log(e);
        }
    };    

    const getStatistics = async() =>{
        try{
            const response = await axios.get(`${SERVER}/interact/statistics`, {
                params : {
                    edumemeID : currentMeme.ID,
                }
            })
            if (response.status === 200) {
                setStatistics(response.data)
            } 
        }catch(error){
            console.log(error)
        }
    }

    const openComments = () =>{
        setOpenStatisticSection(false)
        setOpenCommentSection(true)
        setIsAnimating(true)
    }

    const openStatistics = () =>{
        setOpenCommentSection(false)
        setOpenStatisticSection(true)
        setIsAnimating(true)
    }

    const handleClose = () => {
        setIsAnimating(false);
        setTimeout(() => {
            setOpenCommentSection(false);
            setOpenStatisticSection(false)
            setIsAnimating(false);
        }, 700);
    };

    const handleTouchStart = (e) => {
        setTouchStartY(e.touches[0].clientY);
    };

    const handleTouchMove = (e) => {
        const touchEndY = e.changedTouches[0].clientY;
        const touchDiffY = touchStartY - touchEndY;
        if (touchDiffY > 100) {
            handleNextMeme(); 
        } else if (touchDiffY < -100) {
            handlePreviousMeme();
        }
    };

    useEffect(() => {
        const handleResize = () => {
        if (window.innerWidth <= 768) {
            setViewStyle('Scrolling');
            setIconSize('1x');
        } else {
            setViewStyle('Swiping');
            setIconSize('xl');
        }
        };

        handleResize();

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() =>{
        const fetchChapter = async (chapterID) =>{
            try{
                const response = await axios.get(`${SERVER}/chapters/getChapter`, {
                    params: {
                        chapterID
                    }
                });

                setChapter(response.data);
            }catch(error){
                console.log(error)
            }
        }

        const fetchLesson = async () =>{
            try {
                const response = await axios.get(`${SERVER}/lessons/getLessonByID`, {
                    params: {
                        lessonID: currentMeme.lesson_ID,
                    }
                });
                setLesson(response.data);
                fetchChapter(response.data.chapter_ID)
            } catch (error) {
                console.log(error);
            }
        }
        
        if(currentMeme){
            fetchLesson()
        }
    },[currentMeme])


    useEffect(() =>{
        setLiked(false)
        setSaved(false)
        const fetchInteractions = async () =>{
            try{
                const response = await axios.get(`${SERVER}/interact/getInteractions`, {
                    params: {
                        edumemeID : currentMeme.ID,
                        userUID : user.ID,
                    }
                });

                if (response.status === 200){
                    setComments(response.data.comments)
                    setLiked(response.data.liked)
                    setSaved(response.data.saved)
                }
            }catch(error){
                console.log(error)
            }
        }

        if (user && currentMeme){
            fetchInteractions();
            if (user && user.role !== 'user'){
                getStatistics()
            }
        }
    }, [user, currentMeme])

    useEffect(() => {
        if (!showInformations && videoRef.current) {
            videoRef.current.play();
        }
    }, [showInformations, currentMemeIndex]);

    if (eduMemes.length === 0) {
        return <div style={{minHeight:'100vh', width:'100%', display: 'flex', justifyContent:'center', alignItems: 'center'}}><PacmanLoader color='#2478DC' /></div>
    }

    if (loading) {
        return <div style={{minHeight:'100vh', width:'100%', display: 'flex', justifyContent:'center', alignItems: 'center'}}><PacmanLoader color='#2478DC' /></div>
    }

    if (!user){
        navigate('/authentication/login');
    }else{
        if (viewStyle === 'Swiping'){
            return (
                <div className={`eduMeme ${animationClass}`}>
                    <Helmet>
                        <title>ميمات تعليمية محفوظة</title>
                        <meta name="description" content="استعرض الميمات التعليمية المحفوظة الخاصة بك واستمتع بالتعلم المرح." />
                        <meta property="og:title" content="ميمات تعليمية محفوظة - الميم و التعليم" />
                        <meta property="og:description" content="استعرض الميمات التعليمية المحفوظة الخاصة بك واستمتع بالتعلم المرح." />
                        <meta property="og:image" content="https://edu.memes.tn/preview.png" />
                        <meta property="og:url" content={'https://edu.memes.tn/savedEduMemes/'} />
                        <meta property="og:type" content="website" />
                        <meta property="og:locale" content="ar_TN" />
                        <meta name="twitter:card" content="summary_large_image" />
                        <meta name="twitter:title" content="ميمات تعليمية محفوظة - الميم و التعليم" />
                        <meta name="twitter:description" content="استعرض الميمات التعليمية المحفوظة الخاصة بك واستمتع بالتعلم المرح." />
                        <meta name="twitter:image" content="https://edu.memes.tn/preview.png" />
                        <link rel="canonical" href={'https://edu.memes.tn/savedEduMemes'} />
                    </Helmet>
                    <div className='info-section'>
                        <div className='main-section'>
                            <h2>{currentMeme.title}</h2>
                            <h3>{currentMeme.subtitle}</h3>
                            <h3>{chapter && chapter.title}</h3>
                            <h3>{subject && subject.title}</h3>
                            <div className="container">
                                {user && user.role !== 'user' ?
                                    (
                                        <div className="sections">
                                            <div>
                                                <button onClick={displayStatistics}>إحصائيات</button>
                                                {view === false ? <hr className="active"></hr> : <></>}
                                            </div>
                                            <div>
                                                <button onClick={displayComments}>التعليقات</button>
                                                {view === true ? <hr className="active"></hr> : <></>}
                                            </div>
                                        </div>
                                    ) :
                                    (
                                        <h3 style={{fontSize:'28px'}}>التعليقات</h3>
                                    )   
                                }
                                <hr />
                                {view ? 
                                    (
                                        <div className='comments-section'>
                                            <div className='comments'>
                                                {comments && comments.length > 0 ? (
                                                    comments.map((commentObj, index) => (
                                                        <Comment key={index} userUID={commentObj.user_ID} comment = {commentObj.value} timespan = {commentObj.created_at} />
                                                    ))
                                                ) : (
                                                    <div className="nocomments">
                                                        <img src={extremelycanny} />
                                                        <span> كن انت الاسطورة الاولى و ضع تعليق </span>
                                                    </div>
                                                )}
                                            </div>
                                            <div className='comment-field'>
                                                <input type='text' placeholder='أضف تعليق' value={comment} onChange={(e) => setComment(e.target.value)} onKeyDown={handleCommentKeyDown} />
                                                <button onClick={(e) => addComment(e)}><img src={send} alt='send' /></button>
                                            </div>
                                        </div>
                                    ) : 
                                    (
                                        <div className="statistical-section">
                                            <div className="this-post">
                                                <img src={stats} />
                                                <h3> عدد التفاعلات </h3>
                                                <div className="options">
                                                    <div className="container">
                                                        <FontAwesomeIcon icon={faHeart} size={iconSize} style={{color: '#2478DC' }} />
                                                        <span style={{color: '#2478DC' }}> الإعجابات </span>
                                                        <h4> {statistics && statistics.currentEduMeme.liked} </h4>
                                                    </div>
                                                    <div className="container">
                                                        <FontAwesomeIcon icon={faComment} size={iconSize} style={{color: '#2478DC' }} />
                                                        <span style={{color: '#2478DC' }}> التعليقات </span>
                                                        <h4> {statistics && statistics.currentEduMeme.comments }  </h4>
                                                    </div>
                                                    <div className="container">
                                                        <FontAwesomeIcon icon={faBookmark} size={iconSize} style={{color: '#2478DC' }} />
                                                        <span style={{color: '#2478DC' }}> الحفظ  </span>
                                                        <h4> {statistics && statistics.currentEduMeme.saved } </h4>
                                                    </div>
                                                </div>
                                            </div> 
                                            <div className="this-post">
                                                <img src={loop} />
                                                <h3> المتوسط </h3>
                                                <div className="options">
                                                    <div className="container">
                                                        <FontAwesomeIcon icon={faHeart} size={iconSize} style={{color: '#2478DC' }} />
                                                        <span style={{color: '#2478DC' }}> الإعجابات </span>
                                                        <h4> {statistics && statistics.allEduMemes.liked }  </h4>
                                                    </div>
                                                    <div className="container">
                                                        <FontAwesomeIcon icon={faComment} size={iconSize} style={{color: '#2478DC' }} />
                                                        <span style={{color: '#2478DC' }}> التعليقات </span>
                                                        <h4> {statistics && statistics.allEduMemes.comments }  </h4>
                                                    </div>
                                                    <div className="container">
                                                        <FontAwesomeIcon icon={faBookmark} size={iconSize} style={{color: '#2478DC' }} />
                                                        <span style={{color: '#2478DC' }}> الحفظ  </span>
                                                        <h4> {statistics && statistics.allEduMemes.saved }  </h4>
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>
                                    )
                                }
                            </div>
                            
                        </div>
                    </div>
        
                    <div className='interactions'>
                        <div onClick={async (e) => {
                                    const updatedLiked = await handleLike(e, currentMeme, liked);
                                    setLiked(updatedLiked)
                            }}
                        >
                            <FontAwesomeIcon icon={faHeart} size={iconSize} style={liked ? { color: '#2478DC' } : currentMeme.video ? {color : 'white'} : {}} />
                            <span style={currentMeme.video ? {color : 'white'} : {}}>إعجاب</span>
                        </div>
                        <div onClick={(e) => openComments(e)} className="mobile-comments">
                            <FontAwesomeIcon icon={faComment} size={iconSize} style={currentMeme.video ? {color : 'white'} : {}} />
                            <span style={currentMeme.video ? {color : 'white'} : {}}>التعليقات</span>
                        </div>
                        {user && user.role !== 'user' ? (
                            <>
                                <div className="desktop-stat" onClick={(e) => displayStatistics(e)}>
                                    <FontAwesomeIcon icon={faChartSimple} size={iconSize} />
                                    <span style={currentMeme.video ? {color : 'white'} : {}}>إحصائيات</span>
                                </div>
                                <div className="mobile-stat" onClick={(e) => openStatistics(e)}>
                                    <FontAwesomeIcon icon={faChartSimple} size={iconSize} color={currentMeme.video ? 'white' : 'black'} />
                                    <span style={currentMeme.video ? {color : 'white'} : {}}>إحصائيات</span>
                                </div>
                            </>
                            ) : (
                                <div onClick={async (e) => {
                                    const updatedSaved = await handleSave(e, currentMeme, saved);
                                    setSaved(updatedSaved)
                                    }}
                                >
                                    <FontAwesomeIcon icon={faBookmark} size={iconSize} style={saved ? { color: '#2478DC' } : currentMeme.video ? {color : 'white'} : {}} />
                                    <span style={currentMeme.video ? {color : 'white'} : {}}>حفظ</span>
                                </div>
                            )
                        }
                        <div
                            onClick={handlePreviousMeme}
                            style={{ pointerEvents: currentMemeIndex === 0 ? 'none' : 'auto', opacity: currentMemeIndex === 0 ? 0.5 : 1 }}
                            className="up">
                            <FontAwesomeIcon icon={faCircleChevronUp} size={iconSize} />
                        </div>
                        <div
                            onClick={handleNextMeme}
                            style={{ pointerEvents: currentMemeIndex === eduMemes.length - 1 ? 'none' : 'auto', opacity: currentMemeIndex === eduMemes.length - 1 ? 0.5 : 1 }}
                            className="down">
                            <FontAwesomeIcon icon={faCircleChevronDown} size={iconSize} />
                        </div>
                        {currentMeme.link && <a className="external-link" href={`https://${currentMeme.link}`} target='_blank' rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faLink} style={{ color: "#ffffff", }} size="xs" />
                        </a>}
                    </div>
        
                    <div className={`media-section ${mediaAnimationClass} ${currentMeme.video ? 'dark-mode' : ''}`}>
                        <div className="mobile-title"> 
                            <h2 style={currentMeme.video ? {color : 'white'} : {}}>{currentMeme.title}</h2>
                            <h3 style={currentMeme.video ? {color : '#2478DC'} : {}}>{currentMeme.subtitle}</h3>
                        </div>
                        <div className='actions'>
                            <button onClick={() => navigate('/Profile')}><FontAwesomeIcon icon={faChevronLeft} size='2xl' style={{ color: currentMeme.video ? 'white' : 'black' }} /></button>
                        </div>
                        {currentMeme.dataType === 'text/image' ? (
                            <div className='informations'>
                                { currentMeme.media && <img src={SERVER+currentMeme.media} alt='meme' /> }
                                {currentMeme.text.split('\n').map((t) => (
                                    <span> {t} </span>
                                ))}
                            </div>
                        ) : (
                            <div className='meme'>
                                <video
                                    ref={videoRef}
                                    src={SERVER+currentMeme.media}
                                    controls={false}
                                    onClick={handleVideoClick}
                                    autoPlay
                                />
                            </div>
                        )}
        
                        <div className="mobile-details">
                            <h2 style={currentMeme.video ? {color : 'white'} : {}}>{chapter && chapter.title}</h2>
                            <h3 style={currentMeme.video ? {color : '#2478DC'} : {}}>{subject && subject.title}</h3>
                        </div>
                    </div>
                    
                    {openCommentSection && (
                        <>
                            <div className="overlay" onClick={() => handleClose()}></div> 
                            <div className={`mobile-open-card ${isAnimating ? 'slide-in' : 'slide-out'}`}> 
                                <div className="line"></div>
                                <div className='comments'>
                                    {comments.length > 0 ? (
                                        comments.map((commentObj, index) => (
                                            <Comment key={index} userUID={commentObj.user_ID} comment = {commentObj.value} timespan = {commentObj.created_at} />
                                        ))
                                    ) : (
                                        <div className="nocomments">
                                            <img src={extremelycanny} />
                                            <span> كن انت الاسطورة الاولى و ضع تعليق </span>
                                        </div>
                                    )}
                                </div>
                                <div className='comment-field'>
                                    <input type='text' placeholder='أضف تعليق' value={comment} onChange={(e) => setComment(e.target.value)} onKeyDown={handleCommentKeyDown} />
                                    <button onClick={(e) => addComment(e)}><FontAwesomeIcon icon={faCircleChevronUp} size="xl" /></button>
                                </div>
                            </div>
                        </>
                        
                    )}
        
                    {openStaticticSection && (
                        <>
                            <div className="overlay" onClick={() => handleClose()}></div> 
                            <div className={`mobile-open-card ${isAnimating ? 'slide-in' : 'slide-out'}`}> 
                                <div className="statistical-section">
                                    <div className="this-post">
                                        <img src={stats} />
                                        <h3> عدد التفاعلات </h3>
                                        <div className="options">
                                            <div className="container">
                                                <FontAwesomeIcon icon={faHeart} size={iconSize} style={{color: '#2478DC' }} />
                                                <span style={{color: '#2478DC' }}> الإعجابات </span>
                                                <h4> {statistics && statistics.currentEduMeme.liked} </h4>
                                            </div>
                                            <div className="container">
                                                <FontAwesomeIcon icon={faComment} size={iconSize} style={{color: '#2478DC' }} />
                                                <span style={{color: '#2478DC' }}> التعليقات </span>
                                                <h4> {statistics && statistics.currentEduMeme.comments }  </h4>
                                            </div>
                                            <div className="container">
                                                <FontAwesomeIcon icon={faBookmark} size={iconSize} style={{color: '#2478DC' }} />
                                                <span style={{color: '#2478DC' }}> الحفظ  </span>
                                                <h4> {statistics && statistics.currentEduMeme.saved } </h4>
                                            </div>
                                        </div>
                                    </div> 
                                    <div className="this-post">
                                        <img src={loop} />
                                        <h3> المتوسط </h3>
                                        <div className="options">
                                            <div className="container">
                                                <FontAwesomeIcon icon={faHeart} size={iconSize} style={{color: '#2478DC' }} />
                                                <span style={{color: '#2478DC' }}> الإعجابات </span>
                                                <h4> {statistics && statistics.allEduMemes.liked }  </h4>
                                            </div>
                                            <div className="container">
                                                <FontAwesomeIcon icon={faComment} size={iconSize} style={{color: '#2478DC' }} />
                                                <span style={{color: '#2478DC' }}> التعليقات </span>
                                                <h4> {statistics && statistics.allEduMemes.comments }  </h4>
                                            </div>
                                            <div className="container">
                                                <FontAwesomeIcon icon={faBookmark} size={iconSize} style={{color: '#2478DC' }} />
                                                <span style={{color: '#2478DC' }}> الحفظ  </span>
                                                <h4> {statistics && statistics.allEduMemes.saved }  </h4>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </>
                    )}
                    
                </div>
            );
        }else{
            return(
                <div className="edumemes-scrolling">
                    <button id="navigate" style={{marginRight:'auto', marginLeft: '10px'}} onClick={() => navigate('/Profile')}><FontAwesomeIcon icon={faCircleArrowLeft} size="xl" /></button>
                    <div className="edumeme-chapter">
                        <img src={subject && SERVER+subject.img} alt="subject image" />
                        <div className="chapter-details">
                            <h1> {chapter && chapter.title} </h1>
                            <span> {chapter && chapter.description} </span>
                        </div>
                    </div>
                    <h3> {lesson && lesson.title} </h3>
                    <div className="edumemes-scrolling-main">
                        {eduMemes.length > 0 && eduMemes.map((edumeme) => (
                            <div key={edumeme.ID} className="edumeme-container">
                                <EduMemeCardScroll edumeme={edumeme} user={user} handleLike={handleLike} handleSave={handleSave} />
                            </div>
                        ))}
                    </div>
                </div>
            )
        }
    }
}

export default SavedEduMemes;