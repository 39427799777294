import React, { useEffect, useState } from "react";
import '../scss/pages/SubjectManagement.scss';
import axios from "axios";
import { SERVER } from "../config/config";
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import useAuth from '../useAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import Navbar from "../hooks/Navbar";
import { PacmanLoader } from 'react-spinners';
import { Helmet } from 'react-helmet';

const SubjectManagement = () => {
    const navigate = useNavigate();
    const { subjectEdit } = useParams();
    const {user, loading} = useAuth();
    const [subject, setSubject] = useState({});
    const [navbarVisible, setNavbarVisible] = useState(false);
    const [navbarClosing, setNavbarClosing] = useState(false);
    const [levels, setLevels] = useState([])
    const [level, setLevel] = useState('')
    const [title, setTitle] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [imagePreview, setImagePreview] = useState('');
    const [imagePerc, setImagePerc] = useState(0);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [videoPreview, setVideoPreview] = useState('');
    const [videoPerc, setVideoPerc] = useState(0);
    const [inputs, setInputs] = useState({});

    useEffect(() => {
        const fetchSubject = async () => {
            if (subjectEdit) {
                try {
                    const response = await axios.get(`${SERVER}/subjects/getSubject`, {
                        params: {
                            id : subjectEdit,
                        }
                    });
                    setSubject(response.data);
                    setLevel(response.data.level_ID)
                    setTitle(response.data.title);
                    setImagePreview(SERVER+response.data.img);
                    setVideoPreview(SERVER+response.data.intro_video);
                } catch (error) {
                    console.log(error);
                }
            }
        };

        fetchSubject();
    }, [subjectEdit]);

    useEffect(() => {
        if (selectedImage) {
            setImagePreview(URL.createObjectURL(selectedImage));
        }
    }, [selectedImage]);

    useEffect(() => {
        if (selectedVideo) {
            setVideoPreview(URL.createObjectURL(selectedVideo));
        }
    }, [selectedVideo]);

    useEffect(() =>{
        const fetchLevels = async () =>{
            try{
                const response = await axios.get(`${SERVER}/levels/levels`)
                if (response.status === 200){
                    setLevels(response.data.levels)
                }
            }catch(err){
                console.log(err)
            }
        }

        fetchLevels()
    }, [])

    const handleImageClick = () => {
        document.getElementById('fileInput').click();
    };

    const handleVideoClick = () => {
        document.getElementById('videoInput').click();
    };

    const openNavbar = () => {
        setNavbarClosing(false);
        setNavbarVisible(true);
    };

    const closeNavbar = () => {
        setNavbarClosing(true);
        setTimeout(() => {
            setNavbarVisible(false);
        }, 500);
    };

    const resetFields = () => {
        setTitle('');
        setSelectedImage(null);
        setSelectedVideo(null);
        setImagePreview('');
        setVideoPreview('');
    };

    const saveSubject = async (e) => {
        e.preventDefault();
    
        const formData = new FormData();
        formData.append('title', title); 
        formData.append('level', level);
    
        if (selectedImage) {
            formData.append('image', selectedImage);
        }
        if (selectedVideo) {
            formData.append('video', selectedVideo);
        }
    
        try {
            if (subjectEdit) {
                formData.append('subjectEdit', subjectEdit)
                await axios.put(`${SERVER}/subjects/editSubject`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            } else {
                await axios.post(`${SERVER}/subjects/addSubject`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            }
    
            resetFields();
            navigate('/subjects');
        } catch (error) {
            console.log(error);
        }
    };
    
    if (loading) {
        return <div style={{minHeight:'100vh', width:'100%', display: 'flex', justifyContent:'center', alignItems: 'center'}}><PacmanLoader color='#2478DC' /></div>
    }

    if(!user){
        return <Navigate to="/authentication/login" />;
    }else{
        return (
            <div className="SubjectManagement">
                <div className="navigation">
                    <button id="navigate" onClick={openNavbar}><FontAwesomeIcon icon={faBars} size="xl" /></button>
                </div>
                {navbarVisible && <Navbar toggleNavbar={closeNavbar} closing={navbarClosing} />}
    
                <h1>{subjectEdit ? 'تعديل مادة' : 'أضف مادة'}</h1>
                <form>
                    <div className="subject-main">
                        <div className="textual">
                            <div>
                                <label>المستوى</label>
                                <select value={level} onChange={(e) => setLevel(e.target.value)}>
                                    <option value="" disabled>Select level</option>
                                    {levels.length > 0 && levels.map((level) => (
                                        <option key={level.ID} value={level.ID}>
                                            {level.title}
                                        </option>
                                    ))}
                                </select>
                                <label>الصورة {imagePerc > 0 && imagePerc + '%'}</label>
                                <div className="image-container">
                                    <div>
                                        <input
                                            type="file"
                                            id="fileInput"
                                            style={{ display: 'none' }}
                                            accept="image/*"
                                            onChange={(e) => setSelectedImage(e.target.files[0])}
                                        />
                                        {imagePreview ? (
                                            <img
                                                src={imagePreview}
                                                alt="Uploaded"
                                                id="image-uploaded"
                                                onClick={handleImageClick}
                                            />
                                        ) : (
                                            <button id='image-input' type="button" onClick={handleImageClick}>تحميل</button>
                                        )}
                                    </div>
                                    <div>
                                        <h3>{title ? title : 'عنوان المحور'}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="line"></div>
                        <div className="media">
                            <label>عنوان المادة</label>
                            <input
                                type="text"
                                placeholder="عنوان المادة"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                            <label>فيديو المقدمة {videoPerc > 0 && videoPerc + '%'}</label>
                            <div className="video-container">
                                <div>
                                    <input
                                        type="file"
                                        id="videoInput"
                                        style={{ display: 'none' }}
                                        accept="video/*"
                                        onChange={(e) => setSelectedVideo(e.target.files[0])}
                                    />
                                    {videoPreview ? (
                                        <video
                                            src={videoPreview}
                                            controls
                                            id="video-uploaded"
                                            onClick={handleVideoClick}
                                        />
                                    ) : (
                                        <button id='video-input' type="button" onClick={handleVideoClick}>تحميل</button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="buttons">
                        <button id="submit-button" type="submit" onClick={saveSubject}>حفظ</button>
                        <button id="reset-button" type="button" onClick={resetFields}>رجوع</button>
                    </div>
                </form>
            </div>
        );
    }
};

export default SubjectManagement;
