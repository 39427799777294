import { useState, useEffect } from 'react';
import { getUserInfo } from './config/auth';
import Cookies from 'js-cookie';

const useAuth = () => {
  const token = Cookies.get('token');
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userData = await getUserInfo();
        setUser(userData.user);
      } catch (error) {
        Cookies.remove('token')
        console.error('Failed to fetch user data');
      } finally {
        setLoading(false);
      }
    };

    if (token) {
      fetchUser();
    } else {
      setLoading(false);
    }
  }, [token]);

  return { user, loading };
};

export default useAuth;
