import { useState, useRef } from 'react';
import '../../scss/pages/administration/DeleteAccount.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import uncanny from '../../utils/mrincredible/uncanny.png';
import sad2 from '../../utils/mrincredible/sad2.png';
import slide1 from '../../utils/soundeffects/slide1.mp3'
import slide2 from '../../utils/soundeffects/slide2.mp3'
import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';
import useAuth from '../../useAuth';
import { PacmanLoader } from 'react-spinners';
import { SERVER } from '../../config/config';
import Cookies from 'js-cookie';
import { Helmet } from 'react-helmet';

const DeleteAccount = () => {
    const navigate = useNavigate()
    const {user, loading} = useAuth()
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [verified, setVerified] = useState(false);
    const [verificationMessage, setVerificationMessage] = useState('');
    const [reason, setReason] = useState('');
    const [showTextarea, setShowTextarea] = useState(false);
    const [moveCount, setMoveCount] = useState(0);
    const [maxMoveCount, setMaxMouveCount] = useState(5)
    const textareaRef = useRef(null);
    const confirmButtonRef = useRef(null);
    const audio1 = new Audio(slide1);
    const audio2 = new Audio(slide2);

    const verifyCredentials = async () => {
        try{
            const response = await axios.get(`${SERVER}/user/verifyCredentials`, {
                params : {
                    email,
                    password,
                    UID: user.ID
                }
            })
            if (response.status === 200){
                setVerified(true);
            }
        }catch(err){
            setVerificationMessage(err.response.data.message)
        }
    };

    const handleRadioChange = (event) => {
        setMoveCount(0);    
        const selectedValue = event.target.value;
    
        switch (selectedValue) {
            case 'مخاوف بشأن الخصوصية':
                setMaxMouveCount(5);
                break;
            case 'عدم الاستفادة من الحساب':
                setMaxMouveCount(10);
                break;
            case 'استخدام وقتي بشكل غير فعال':
                setMaxMouveCount(5);
                break;
            case 'الانتقال إلى خدمة بديلة':
                setMaxMouveCount(15);
                break;
            case 'تجربة مستخدم غير مرضية':
                setMaxMouveCount(15);
                break;
            case 'المحتوى لا يناسبني':
                setMaxMouveCount(10);
                break;
            case 'لدي حساب آخر':
                setMaxMouveCount(3);
                break;
            case 'other':
                setMaxMouveCount(10);
                break;
            default:
                setMaxMouveCount(5);
                break;
        }
    
        if (selectedValue === 'other') {
            setReason('');
            setShowTextarea(true);
            setTimeout(() => {
                textareaRef.current?.scrollIntoView({ behavior: 'smooth' });
                textareaRef.current?.focus();
            }, 100); 
        } else {
            setShowTextarea(false);
            setReason(selectedValue);
        }
    };
    

    const handleHover = () => {
        if (reason !== '' && moveCount < maxMoveCount) {
            const randomX = Math.floor(Math.random() * (window.innerWidth - 150)); 
            const randomY = Math.floor(Math.random() * (window.innerHeight - 150));

            if (confirmButtonRef.current) {
                confirmButtonRef.current.style.width = window.innerWidth <= 768 ? '50%' : '10%';
                confirmButtonRef.current.style.opacity = '0.8';
                confirmButtonRef.current.style.position = 'fixed';
                confirmButtonRef.current.style.left = `${randomX}px`;
                confirmButtonRef.current.style.top = `${randomY}px`;
            }

            document.querySelector('form').classList.add('disable-pointer-events');

            if (moveCount % 2 === 0) {
                audio1.currentTime = 0;
                audio1.play();
            } else {
                audio2.currentTime = 0;
                audio2.play();
            }

            setMoveCount(moveCount + 1);
        } else {
            if (confirmButtonRef.current) {
                confirmButtonRef.current.style.position = 'static';
                confirmButtonRef.current.style.width = window.innerWidth <= 768 ? '50%' : '10%';
                confirmButtonRef.current.style.opacity = '1';
            }
            document.querySelector('form').classList.remove('disable-pointer-events');
        }
    };

    const ConfirmDelete = async () => {
        try{
            const response = await axios.put(`${SERVER}/user/setAccountForRemoval`, {
                UID : user.ID,
                reason
            })
            if (response.status === 200){
                await axios.post(`${SERVER}/auth/logout`, {}, { withCredentials: true });
                Cookies.remove('token')
                navigate(`/AccountSetForRemoval`)
            }
        }catch(err){
            console.log(err)
        }
    };
    if (loading) {
        return <div style={{minHeight:'100vh', width:'100%', display: 'flex', justifyContent:'center', alignItems: 'center'}}><PacmanLoader color='#2478DC' /></div>
    }

    if(!user){
        return <Navigate to="/authentication/login" />;
    }else{
        return (
            <div className="deleteAccount">
                <Helmet>
                    <title>حذف الحساب</title>
                    <meta name="description" content="قم بحذف حسابك من منصة الميم و التعليم." />
                    <meta property="og:title" content="حذف الحساب - الميم و التعليم" />
                    <meta property="og:description" content="قم بحذف حسابك من منصة الميم و التعليم." />
                    <meta property="og:image" content="https://edu.memes.tn/preview.png" />
                    <meta property="og:url" content="https://edu.memes.tn/DeleteAccount" />
                    <meta property="og:type" content="website" />
                    <meta property="og:locale" content="ar_TN" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="حذف الحساب - الميم و التعليم" />
                    <meta name="twitter:description" content="قم بحذف حسابك من منصة الميم و التعليم." />
                    <meta name="twitter:image" content="https://edu.memes.tn/preview.png" />
                    <link rel="canonical" href="https://edu.memes.tn/DeleteAccount" />
                </Helmet>
                {verified === false ? (
                    <div className='verifyIdentity'>
                        <h1> حذف الحساب </h1>
                        <img src={uncanny} alt='حذف الحساب' />
                        <form>
                            <span> {verificationMessage} </span>
                            <label>البريد الإلكتروني</label>
                            <input type='email' placeholder='البريد الإلكتروني' onChange={(e) => setEmail(e.target.value)} required />
                            <label style={{ marginTop: '30px' }}>كلمة المرور</label>
                            <div className="password-input">
                                <input type={showPassword ? 'text' : 'password'} placeholder='كلمة المرور' onChange={(e) => setPassword(e.target.value)} required />
                                <FontAwesomeIcon
                                    icon={showPassword ? faEye : faEyeSlash}
                                    onClick={() => setShowPassword(!showPassword)}
                                    color='#2478DC'
                                    size='xs'
                                />
                            </div>
                        </form>
                        <button id='submit' onClick={verifyCredentials}>حذف الحساب</button>
                    </div>
                ) : (
                    <div className='why'>
                        <h1>لماذا تريد حذف حسابك؟</h1><br/>
                        <div className='image-container'>
                            <img src={sad2} alt='حذف الحساب' />
                        </div>
                        <form>
                            <div>
                                <input type="radio" id="privacy" name="reason" value="مخاوف بشأن الخصوصية" onChange={handleRadioChange} />
                                <label htmlFor="privacy">مخاوف بشأن الخصوصية</label><br/>
                            </div>
                            <div>
                                <input type="radio" id="notUseful" name="reason" value="عدم الاستفادة من الحساب" onChange={handleRadioChange} />
                                <label htmlFor="notUseful">عدم الاستفادة من الحساب</label><br/>
                            </div>
                            <div>
                                <input type="radio" id="ineffectiveUse" name="reason" value="استخدام وقتي بشكل غير فعال" onChange={handleRadioChange} />
                                <label htmlFor="ineffectiveUse">استخدام وقتي بشكل غير فعال</label><br/>
                            </div>
                            <div>
                                <input type="radio" id="switchService" name="reason" value="الانتقال إلى خدمة بديلة" onChange={handleRadioChange} />
                                <label htmlFor="switchService">الانتقال إلى خدمة بديلة</label><br/>
                            </div>
                            <div>
                                <input type="radio" id="badExperience" name="reason" value="تجربة مستخدم غير مرضية" onChange={handleRadioChange} />
                                <label htmlFor="badExperience">تجربة مستخدم غير مرضية</label><br/>
                            </div>
                            <div>
                                <input type="radio" id="contentMismatch" name="reason" value="المحتوى لا يناسبني" onChange={handleRadioChange} />
                                <label htmlFor="contentMismatch">المحتوى لا يناسبني</label><br/>
                            </div>
                            <div>
                                <input type="radio" id="haveAnotherAccount" name="reason" value="لدي حساب آخر" onChange={handleRadioChange} />
                                <label htmlFor="haveAnotherAccount">لدي حساب آخر</label><br/>
                            </div>
                            <div>
                                <input type="radio" id="other" name="reason" value="other" onChange={handleRadioChange} />
                                <label htmlFor="other">أسباب أخرى</label><br/>
                            </div>
                            {showTextarea && (
                                <textarea
                                    ref={textareaRef}
                                    placeholder="ما هو السبب؟"
                                    value={reason}
                                    onChange={(e) => setReason(e.target.value)}
                                />
                            )}
                        </form>
                        <button
                            id="confirm"
                            ref={confirmButtonRef}
                            onMouseEnter={handleHover}
                            onClick={() => {
                                if (moveCount === maxMoveCount) {
                                    ConfirmDelete();
                                }
                            }}
                        >
                            حذف الحساب
                        </button>
                    </div>
                )}
            </div>
        );
    }
};

export default DeleteAccount;
