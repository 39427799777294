// Comment.jsx
import React, { useEffect, useState } from 'react';
import '../scss/utils/Comment.scss';  // Make sure to adjust the path if needed
import user from '../utils/others/edu_bg.png';  // Adjust the path as per your project structure
import axios from 'axios';
import { SERVER  } from '../config/config';

const Comment = ({ userUID, comment, timespan }) => {
    const [username, setUsername] = useState();
    
    const formatTimespan = (timestamp) => {
    const now = new Date();
    const commentDate = new Date(timestamp);
    const diffMs = now - commentDate;

    const diffSec = Math.floor(diffMs / 1000);
    const diffMin = Math.floor(diffSec / 60);
    const diffHour = Math.floor(diffMin / 60);
    const diffDay = Math.floor(diffHour / 24);
    const diffMonth = Math.floor(diffDay / 30);
    const diffYear = Math.floor(diffDay / 365);

    if (diffSec < 60) {
        return 'now';
    } else if (diffMin < 60) {
        return `${diffMin}min${diffMin > 1 ? 's' : ''}`;
    } else if (diffHour < 24) {
        return `${diffHour}h${diffHour > 1 ? 's' : ''}`;
    } else if (diffDay < 30) {
        return `${diffDay}d`;
    } else if (diffMonth < 12) {
        return `${diffMonth}mo`;
    } else {
        return `${diffYear}yr${diffYear > 1 ? 's' : ''}`;
    }
};

    
    useEffect(() =>{
        const getUsername = async () =>{
            try{
                const response = await axios.get(`${SERVER}/user/getUsername`, {
                    params :{
                        userUID : userUID
                    }
                })
    
                if (response.status === 200){
                    setUsername(response.data.username)
                }
            }catch(error){
                console.log(error)
            }
        }

        getUsername()
    }, [userUID])
    

    return (
        <div className="comment">
            <div className='comment-content'>
                <div className='user'>
                    <img src={user} alt="user avatar" />
                </div>
                <div className='content'>
                    <h5>{username}</h5>
                    <span>{comment}</span>
                </div>
            </div>
            <div className='timespan'>
                {timespan && formatTimespan(timespan)}
            </div>
        </div>
    );
};

export default Comment;
