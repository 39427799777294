import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SERVER } from "../config/config";
import { faLink, faBookmark, faComment, faHeart, faCircleChevronUp } from "@fortawesome/free-solid-svg-icons";
import { useRef, useState, useEffect } from "react";
import '../scss/utils/EduMemeCardScroll.scss'
import axios from "axios";
import Comment from "./Comment";
import extremelycanny from '../utils/mrincredible/extremelycanny.png'

const EduMemeCardScroll = ({ edumeme, user, handleLike, handleSave }) => {
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [comment, setComment] = useState('');
    const [openCommentSection, setOpenCommentSection] = useState(false)
    const [isAnimating, setIsAnimating] = useState(false);
    const [liked, setLiked] = useState(false);
    const [comments, setComments] = useState([]);
    const [saved, setSaved] = useState(false);

    const openComments = () =>{
        setOpenCommentSection(true)
        setIsAnimating(true)
    }

    const handleClose = () => {
        setIsAnimating(false);
        setTimeout(() => {
            setOpenCommentSection(false);
            setIsAnimating(false);
        }, 700);
    };

    const addComment = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${SERVER}/interact/comment`, {
                edumemeID : edumeme.ID,
                userUID : user.ID,
                comment
            });
            if (response.status === 201) {
                setComments([response.data, ...comments]);
                setComment('');
            }
        } catch (e) {
            console.log(e);
        }
    }

    const handleCommentKeyDown = async (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (comment.trim() === '') {
                return;
            }
            try {
                const response = await axios.post(`${SERVER}/interact/comment`, {
                    edumemeID : edumeme.ID,
                    userUID : user.ID,
                    comment
                });
    
                if (response.status === 201) {
                    setComments([response.data, ...comments]);
                    setComment('');
                }
            } catch (e) {
                console.log(e);
            }
        }
    };

    const handleVideoClick = () => {
        if (isPlaying) {
        videoRef.current.pause();
        } else {
        videoRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    useEffect(() => {
        const videoElement = videoRef.current;

        const observer = new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
            if (entry.isIntersecting) {
                videoElement.muted = false;
                videoElement.play().catch((e) => {
                console.log('Autoplay failed:', e);
                });
                setIsPlaying(true);
            } else {
                videoElement.pause();
                setIsPlaying(false);
            }
            });
        },
        {
            threshold: 0.9,
        }
        );

        if (videoElement) {
        observer.observe(videoElement);
        }

        return () => {
        if (videoElement) {
            observer.unobserve(videoElement);
        }
        };
    }, []);

    useEffect(() =>{
        setLiked(false);
        setSaved(false);
        const fetchInteractions = async () =>{
            try{
                const response = await axios.get(`${SERVER}/interact/getInteractions`, {
                    params: {
                        edumemeID : edumeme.ID,
                        userUID : user.ID,
                    }
                });

                if (response.status === 200){
                    setComments(response.data.comments);
                    setLiked(response.data.liked);
                    setSaved(response.data.saved);
                }
            } catch(error) {
                console.log(error);
            }
        }

        if (user && edumeme){
            fetchInteractions();
        }
    }, [user, edumeme]);

    return (
        <div className="edumeme-scroll-card">
            <h1>{edumeme.title && edumeme.title}</h1>
            <h3>{edumeme.subtitle && edumeme.subtitle}</h3>
            {edumeme.link && (
                <a href={`https://${edumeme.link}`} id="link" target="_blank">
                    <FontAwesomeIcon icon={faLink} />
                </a>
            )}
            {edumeme.dataType === "text/image" ? (
                <div className="type-image">
                    {edumeme.text && edumeme.text.split('\n').map((t) => (
                        <span> {t} </span>
                    ))}
                    {edumeme.media && <img src={SERVER + edumeme.media} alt="EduMeme" />}
                </div>
            ) : (
                <div className="type-video">
                <video
                    ref={videoRef}
                    src={edumeme.media && SERVER + edumeme.media}
                    controls={false}
                    onClick={handleVideoClick}
                    autoPlay={false}
                    muted
                />
                </div>
            )}
            <div className="interactions">
                <div
                    onClick={async (e) => {
                        const updatedLiked = await handleLike(e, edumeme, liked);
                        setLiked(updatedLiked);
                    }}
                >
                    <FontAwesomeIcon icon={faHeart} style={liked ? { color: '#2478DC' } : {color: 'black'}} />
                    <span> إعجاب </span>
                </div>
                <div onClick={(e) => openComments(e)}>
                    <FontAwesomeIcon icon={faComment} /> <span> تعليق </span>
                </div>
                <div onClick={async (e) => {
                    const updatedSaved = await handleSave(e, edumeme, saved);
                    setSaved(updatedSaved);
                }}>
                    <FontAwesomeIcon icon={faBookmark} style={saved ? { color: '#2478DC' } : {color: 'black'}} />
                    <span> حفظ </span>
                </div>
            </div>
            {openCommentSection && (
                <>
                    <div className="overlay" onClick={() => handleClose()}></div> 
                    <div className={`mobile-open-card ${!isAnimating ? 'slide-in' : 'slide-out'}`}> 
                        <div className="line" onClick={() => handleClose()}></div>
                        <div className='comments'>
                            {comments.length > 0 ? (
                                comments.map((commentObj, index) => (
                                    <Comment key={index} userUID={commentObj.user_ID} comment = {commentObj.value} timespan = {commentObj.created_at} />
                                ))
                            ) : (
                                <div className="nocomments">
                                    <img src={extremelycanny} />
                                    <span> كن انت الاسطورة الاولى و ضع تعليق </span>
                                </div>
                            )}
                        </div>
                        <div className='comment-field'>
                            <input type='text' placeholder='أضف تعليق' value={comment} onChange={(e) => setComment(e.target.value)} onKeyDown={handleCommentKeyDown} />
                            <button onClick={(e) => addComment(e)}><FontAwesomeIcon icon={faCircleChevronUp} size="xl" /></button>
                        </div>
                    </div>
                </>
                
            )}
        </div>
    );
};

export default EduMemeCardScroll;
